import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { LuPanelLeftClose } from 'react-icons/lu';
import { ContextApp } from '../utils/Context';
import WalletConnect from './WalletConnect';
import FolderWrapper from './FolderWrapper';
import { getFormattedCurrentDate } from '../utils';
import { TooltipContent } from './TooltipContent';
import NavInputDialog, { DeleteDialog } from './NavDialog';
import {
  fetchAllFoldersByWallet,
  fetchAllTopicsByFolderId,
  createNewTopicInFolder,
  createNewFolder,
  updateTopic,
  deleteTopicById,
  updateFolder,
  deleteFolderById,
} from '../utils/API';

function LeftNav() {
  const {
    setShowSlide,
    showSlide,
    isLoggedIn,
    currentTopic,
    setCurrentTopic,
    refetchTopics,
    setRefetchTopics,
    currentWallet,
  } = useContext(ContextApp);
  const leftNavbarTop = useRef(null);

  const params = useParams();
  const navigate = useNavigate();
  const { topicId } = params || {};
  const [isFolder] = useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [hoveredTopicId, setHoveredTopicId] = useState(null);
  const [newFolder, setNewFolder] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editTopicId, setEditTopicId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  const [editingFolder, setEditingFolder] = useState(false);
  const [editFolderId, setEditFolderId] = useState(null);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [topicIdToDelete, setTopicIdToDelete] = useState(null);
  const { data = {}, refetch: refetchfolders } = useQuery(
    'fetchAllFoldersByWallet',
    fetchAllFoldersByWallet,
    {
      enabled: isLoggedIn,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const folders = data?.folders ?? [];

  const { data: topicsObj = {}, refetch: refetchTopicsForFolder } = useQuery(
    ['fetchAllTopicsByFolderId', selectedFolderId],
    () =>
      selectedFolderId
        ? fetchAllTopicsByFolderId(selectedFolderId)
        : { topic: [] },
    {
      enabled: isLoggedIn && folders.length > 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const topics = topicsObj?.topics ?? [];

  useEffect(() => {
    if (!selectedFolderId && folders.length) {
      setSelectedFolderId(folders[0].id);
    }
  }, [folders, selectedFolderId, topics]);

  useEffect(() => {
    if (!topicId && topics.length) {
      const currentTopicId = topics[0].id;
      navigate(`/chat/${currentTopicId}`);
    }

    if (topicId && topics.length && topicId === topicIdToDelete) {
      const currentTopicId = topics[0].id;
      setTopicIdToDelete(null);
      navigate(`/chat/${currentTopicId}`);
    }
  }, [currentTopic, topics, topicId]);

  useEffect(() => {
    if (refetchTopics) {
      refetchTopicsForFolder(selectedFolderId);
      setRefetchTopics(false);
    }
  }, [refetchTopics]);

  useEffect(() => {
    if (currentWallet) {
      refetchfolders();
    }
  }, [currentWallet]);

  const handleCreateNewTopic = async (folderId) => {
    try {
      const res = await createNewTopicInFolder({
        folderId,
        title: `Topic - ${getFormattedCurrentDate()}`,
      });
      await refetchTopicsForFolder(folderId);
      const topicsArray = res?.topics ?? [];
      if (!topicsArray.length) {
        return;
      }
      leftNavbarTop.current.scrollIntoView();
      const newTopic = topicsArray[0];
      if (newTopic?.id) {
        setCurrentTopic(newTopic.id);
        navigate(`/chat/${newTopic.id}`);
      }
    } catch (e) {
      console.error('Error creating new topic:', e);
    }
  };

  const handleUpdateTopicEdit = (e, topic) => {
    e.preventDefault();
    e.stopPropagation();
    setEditTopicId(topic.id);
    setEditedTitle(topic.title);
    setIsEditing(true);
    setOpenEditDialog(true);
    setEditingFolder(false);
    setDeleteDialogOpen(false);
  };

  const handleSaveTopicEdit = async () => {
    if (!editedTitle.trim()) {
      console.error('Title cannot be empty.');
      return;
    }
    try {
      const topicId = editTopicId;
      await updateTopic({ topicId, title: editedTitle });
      refetchTopicsForFolder(selectedFolderId);
      setEditTopicId(null);
      setIsEditing(false);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error saving edited topic:', error);
    }
  };

  const handleTopicDelete = (e, topicId) => {
    e.preventDefault();
    e.stopPropagation();
    setTopicIdToDelete(topicId);
    setDeleteDialogOpen(true);
    setEditingFolder(false);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const confirmDeleteTopic = async () => {
    try {
      await deleteTopicById(topicIdToDelete);
      refetchTopicsForFolder();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting topic:', error);
    }
  };

  const handleAddNewFolder = async () => {
    try {
      if (!newFolder.trim()) {
        console.error('Folder name cannot be empty.');
        return;
      }
      await createNewFolder({
        wallet: localStorage.getItem('wallet'),
        title: newFolder,
      });
      refetchfolders();
      setNewFolder('');
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating new folder:', error);
    }
  };

  const handleUpdateFolderTitle = (folder) => {
    setEditFolderId(folder.id);
    setEditedTitle(folder.title);
    setIsEditing(true);
    setOpenEditDialog(true);
    setEditingFolder(true);
  };

  const handleSaveFolderEdit = async () => {
    if (!editedTitle.trim()) {
      console.error('Title cannot be empty.');
      return;
    }
    try {
      const updatedFolderTitle = editedTitle;
      const folderId = editFolderId;
      await updateFolder({ folderId, title: updatedFolderTitle });
      refetchfolders();
      setEditFolderId(null);
      setIsEditing(false);
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error saving edited folder title:', error);
    }
  };

  const handleFolderDelete = (e, folderId) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteFolderId(folderId);
    setCurrentTopic(null);
    setDeleteDialogOpen(true);
    setEditingFolder(true);
  };

  const confirmDeleteFolder = async () => {
    try {
      await deleteFolderById(deleteFolderId);
      await refetchfolders();
      setDeleteDialogOpen(false);
      setDeleteFolderId(null);
      setSelectedFolderId(null);
      setSelectedFolderId(null);
    } catch (error) {
      console.error('Error deleting topic:', error);
    }
  };

  const handleChangeFolderName = (event) => {
    setNewFolder(event.target.value);
  };

  const handleopenDialog = () => {
    setopenDialog(true);
  };

  const handleCloseDialog = () => {
    setopenDialog(false);
    setNewFolder('');
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setIsEditing(false);
  };

  const handleFolderSelect = (folderId) => {
    setSelectedFolderId(folderId);
    refetchTopicsForFolder();
  };

  return (
    <div
      className={
        !showSlide
          ? 'h-[100dvh] bg-gray-900 w-[300px] border-r border-gray-500 hidden lg:flex items-center justify-between p-2 text-white flex-col translate-x-0 z-10'
          : 'hidden'
      }
    >
      <div className="flex items-start justify-between w-full">
        <span
          className={`border border-gray-600 rounded w-[80%] py-2 text-xs flex gap-1 items-center justify-center ${
            isLoggedIn ? 'cursor-pointer' : 'color-gray-700'
          }`}
          onClick={isLoggedIn ? handleopenDialog : null}
        >
          <AiOutlinePlus fontSize={18} />
          New Folder
        </span>
        <NavInputDialog
          open={openDialog}
          onClose={handleCloseDialog}
          value={newFolder}
          onChange={handleChangeFolderName}
          handleAddNewFolder={handleAddNewFolder}
        />
        <span
          className="border border-gray-600 rounded px-3 py-[9px] flex items-center justify-center cursor-pointer"
          title="Close sidebar"
          onClick={() => setShowSlide(!showSlide)}
        >
          <LuPanelLeftClose />
        </span>
      </div>
      {/* middle section */}
      <div className="h-[75%] w-full flex items-start justify-start flex-col overflow-hidden overflow-y-auto text-sm scroll my-2">
        <div ref={leftNavbarTop} />
        {isLoggedIn && (
          <FolderWrapper
            handleNewTopic={(folderId) =>
              isLoggedIn && handleCreateNewTopic(folderId)
            }
            handleUpdateFolderTitle={handleUpdateFolderTitle}
            handleFolderDelete={handleFolderDelete}
            isFolder={isFolder}
            isEditing={isEditing}
            folders={folders}
            handleSaveFolderEdit={handleSaveFolderEdit}
            editedTitle={editedTitle}
            openDialog={openDialog}
            openEditDialog={openEditDialog}
            refetchTopicsForFolder={handleFolderSelect}
            defaultExpandedFolder={selectedFolderId}
          >
            {topics.map((topic) => (
              <>
                <Link
                  key={topic.id}
                  to={`/chat/${topic.id}`}
                  onMouseEnter={() => {
                    if (hoveredTopicId !== topic.id) {
                      setHoveredTopicId(topic.id);
                    }
                  }}
                  onMouseLeave={() => {
                    setHoveredTopicId(null);
                  }}
                >
                  <TooltipContent
                    item={topic}
                    currentPageTopicId={topicId}
                    onEdit={handleUpdateTopicEdit}
                    onDelete={handleTopicDelete}
                    hoveredItemId={hoveredTopicId}
                    editTopicId={editTopicId}
                    setEditTopicId={setEditTopicId}
                    editedTitle={editedTitle}
                    setEditedTitle={setEditedTitle}
                    handleSaveEdit={handleSaveTopicEdit}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    openDialog={openDialog}
                    openEditDialog={openEditDialog}
                  />
                </Link>
              </>
            ))}
          </FolderWrapper>
        )}
        {isEditing && (
          <NavInputDialog
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            isEditing={true}
            editedTitle={editedTitle}
            setEditedTitle={setEditedTitle}
            isFolder={editingFolder}
            disableBackdropClick={true}
            dialogTitle={editingFolder ? 'Edit Folder Name' : 'Edit Topic Name'}
            placeholder={editingFolder ? 'New Folder Name' : 'New Topic Name'}
            PaperProps={{
              style: {
                zIndex: 1300,
              },
            }}
            BackdropProps={{
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
            onCancel={() => {
              setEditTopicId(null);
              setIsEditing(false);
            }}
            onSave={() => {
              if (editingFolder) {
                handleSaveFolderEdit();
              } else {
                handleSaveTopicEdit();
              }
            }}
          />
        )}
        <DeleteDialog
          deleteTitle={editingFolder ? 'Delete Folder' : 'Delete Topic'}
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
          onDelete={() => {
            if (editingFolder) {
              confirmDeleteFolder();
            } else {
              confirmDeleteTopic();
            }
          }}
        />
      </div>

      {/* bottom section  */}
      <div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
        <span
          className="rounded w-full py-2 px-2 text-xs flex gap-1 items-center justify-between cursor-pointer hover:bg-gray-800 transition-all duration-300"
          style={{
            border: '2px solid rgb(70, 70, 70)',
          }}
        >
          <span className="flex gap-1 items-center justify-center text-sm">
            <img
              src={'/eth.png'}
              alt="user"
              className="w-6 h-6 rounded object-cover"
            />
            Join zkGPT
          </span>
          <span className="rounded-md bg-yellow-200 px-1.5 py-0.5 text-xs font-medium text-gray-800">
            Ad
          </span>
        </span>
        <WalletConnect />
      </div>
    </div>
  );
}

export default LeftNav;
